//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------
//---------------------------------------------
// 1. Basics
//---------------------------------------------
@import "variables";
@import "bootstrap";
@import "fonts";
//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
@import url("https://fonts.googleapis.com/css?family=Lora");
@import url("https://fonts.googleapis.com/css?family=Vollkorn+SC");
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

body {
  color: white;
  background-color: $secondary;
  font-family: Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
}

p {
  font-family: "Vollkorn", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

h1 {
  font-family: "Vollkorn SC", sans-serif;
  font-size: calc(1.375rem + 1.5vw);
  padding-left: 20px;
  padding-right: 20px;
}

section {
  &.content {
    margin-top: 3rem;
  }
}

#splide-homunculi {
  .splide__slide {
    opacity: 0.5;
  }

  .is-active {
    opacity: 1;
  }
}

#header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

#header-text {
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, -50%);
  font-weight: 300;
  text-align: center;
  width: 100%;
  font-size: 150%;
}

#clouds {
  background-image: url("/images/sections/clouds.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  width: 100vw;
  left: 0;
  background-attachment: scroll;
  background-position: center;
  overflow: hidden;
}

#logo {
  background-image: url("/images/Logo.png");
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-y: 20%;
  background-size: 300px;
}

#mountains {
  background: url("/images/sections/mountains.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  width: 100%;
  height: 50%;
  min-width: 633px;
  background-attachment: scroll;
  background-position-x: center;
  background-position-y: top;
}

#mountains2 {
  background: url("/images/sections/mountains_02.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 335px;
  background-position: top;
  position: relative;
}

#mountains3 {
  background: url("/images/sections/mountains_03.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 335px;
  background-position: top;
  position: relative;
}

#mountains4 {
  background: url("/images/sections/mountains_04.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 505px;
  background-position: top;
  position: relative;
}

#mountains5 {
  background: url("/images/sections/mountains_05.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 447px;
  background-position: top;
  position: relative;
}

#footer {
  background: url("/images/sections/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  padding-bottom: 350px;
  background-position: top;
  position: absolute;
  display: flex;
  flex-direction: column;

  .links {
    font-family: "Vollkorn", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: white;
  }

  .container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;

    .row {
      div {
        padding-top: 2em;
      }
    }

    a {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 2em;
      width: 100%;
    }

    .social {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .terms {
        flex-basis: 193px;
      }
    }

    .social>div {
      flex-basis: 82px;
    }

    .social-icon {
      height: 62px;
      width: 62px;
      display: flex;
      justify-content: center;
    }

    .footer-small-screen {
      display: none;
    }

    .twitter-social {
      background-image: url("/images/icons/twitter_logo.png");
    }

    .egld-community-social {
      min-width: 150px !important;
      background-image: url("/images/icons/egld_community_logo.png");
    }

    .medium-social {
      background-image: url("/images/icons/medium_logo.png");
    }

    .terms-text {
      text-wrap: nowrap;
      font-size: 20px !important;
      line-height: 20px !important;
    }

    .discord-social {
      background-image: url("/images/icons/discord_logo.png");
    }

    .multivers-social {
      background-image: url("/images/icons/multiversx_x_logo.png");
    }
  }
}

#carousel {
  margin-bottom: 50px;
  margin-top: 3rem;
}

.container {
  max-width: 850px;
}

.start-game {
  max-height: 93px;
  // min-height: 73px;
  max-width: 100%;

  &__collections {
    max-height: 73px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  &__left {
    margin-right: 0
  }

  &__mid-section {
    max-width: 433px;
  }
}

.gameplay-black-line {
  max-width: 800px;
}

.phone-img {
  max-height: 470px;
}

.homunculi-img {
  max-height: 250px;
}

.gallery-img {
  max-height: 250px;
}

.technology-expo {
  max-height: 320px;
  width: 100%;
  object-fit: contain;
}

.multiversx {
  max-height: 40px;
  width: 100%;
  object-fit: contain;
}

.frame {
  &__homunculi {
    height: 300px;
    width: auto;
    max-width: 100%;
    object-fit: cover;
  }
}

.first-content {
  // margin-top: -280px;  when having also alpha mint button
  margin-top: -200px;
  z-index: 999;
  position: relative;
}

.background-beige {
  width: 100%;
  margin-top: -100px;
  padding-top: 50px;
  margin-bottom: -300px;
  padding-bottom: 300px;
  z-index: 1;
  background-color: #ffffff;
  color: black;
}

.fadeout-beige-div {
  margin-top: -50px;
  z-index: 999;
  position: relative;
  width: 100%;
  height: 150px;
  // background: linear-gradient(to bottom, rgba(248, 233, 223, 0) 0%, rgba(248, 233, 223, 1) 10%, rgba(248, 233, 223, 1) 90%, rgba(248, 233, 223, 0) 100%);
}

.fadeout-dark-div {
  margin-top: -50px;
  z-index: 999;
  position: relative;
  width: 100%;
  height: 150px;
  // background: linear-gradient(to bottom, rgba(41, 40, 53, 0) 0%, rgba(41, 40, 53, 1) 10%, rgba(41, 40, 53, 1) 90%, rgba(41, 40, 53, 0) 100%);
}

.background-gallery {
  margin-top: -100px;
  padding-top: 50px;
  margin-bottom: -300px;
  padding-bottom: 300px;
  z-index: 1;
  background-color: #f8e9df;
  width: 100%;
  background-color: black;
}

.btn.disabled {
  pointer-events: auto;
}

.multi-item-carousel {
  .carousel-inner {
    >.item {
      transition: 500ms ease-in-out left;
    }

    .active {
      &.left {
        left: -33%;
      }

      &.right {
        left: 33%;
      }
    }

    .next {
      left: 33%;
    }

    .prev {
      left: -33%;
    }

    @media all and (transform-3d),
    (-webkit-transform-3d) {
      >.item {
        // use your favourite prefixer here
        transition: 500ms ease-in-out left;
        transition: 500ms ease-in-out all;
        backface-visibility: visible;
        transform: none !important;
      }
    }
  }

  .carouse-control {

    &.left,
    &.right {
      background-image: none;
    }
  }
}

.team {
  &__item-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__description {
    font-family: "Vollkorn", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  &__picture {
    max-width: 100%;
    height: auto;
    border-radius: 30px;
    border: solid white 5px;
  }
}

.roadmap {
  &__main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  &__lateral-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 256px;
    gap: 15px;

    &__left {
      margin-top: 360px
    }
  }

  &__step-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  &__description {
    font-size: 18px !important;
    line-height: 18px !important;
    margin-bottom: 0px;
  }

  &__picture {
    height: 143px;
    width: 143px;
    border-radius: 30px;
    border: solid white 5px;

    &__container {
      position: relative;
    }

    &__check-mark {
      position: absolute;
      height: 50px;
      width: 50px;
      bottom: -10px;
      right: -15px;
      fill: greenyellow;
    }
  }

  &__feather {
    min-height: 2450px;
    margin-left: -50px;
    margin-right: -50px;
  }
}

.decision-text {
  font-size: 35px !important;
}

div.scroll-container {
  background-color: black;
  overflow: auto;
  white-space: nowrap;
  padding: 10px;
}

div.scroll-container img {
  padding: 10px;
}

.scroll-container::-webkit-scrollbar {
  width: 12px;
}

.scroll-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.carousel {
  display: flex;
  width: 100%;
  justify-content: center;

  .carousel-inner {
    width: 80%;

    .active {
      &.left {
        left: -33%;
      }

      &.right {
        left: 33%;
      }
    }

    .next {
      left: 33%;
    }

    .prev {
      left: -33%;
    }
  }

  .carousel-control-prev {
    justify-content: start;
    filter: invert(100%);
  }

  .carousel-control-next {
    justify-content: end;
    filter: invert(100%);
  }

  .carousel-item {
    padding-bottom: 100px;
    min-height: 770px;

    &__phone {
      min-height: 480px;
      height: 480px;
    }

    &__gallery {
      min-height: 260px;
    }
  }

  .carousel-indicators {
    button {
      height: 25px;
      width: 25px;
      border-radius: 15px;
      background-color: white;
      border: 3px solid black;
      opacity: 1;

      &.active {
        background-color: black;
      }
    }
  }
}

.carousel-gallery {
  .carousel-control-prev {
    justify-content: start;
    filter: invert(0%) !important;
  }

  .carousel-control-next {
    justify-content: end;
    filter: invert(0%) !important;
  }
}

.carousel-img {
  max-width: 70dvw;
  margin-bottom: 10px;
}

.fadeover {
  background: linear-gradient(180deg, rgba(var(--bs-secondary-rgb), 0) 58%, rgba(var(--bs-secondary-rgb), 1) 100%);
  position: absolute;
  height: 200px;
  width: 100vw;
  bottom: 0;
}

.fadeover-beige {
  background: linear-gradient(180deg, rgba(248, 233, 223, 0) 58%, rgba(248, 233, 223, 1) 100%);
  position: absolute;
  height: 50px;
  width: 100vw;
  bottom: 0;
}

.text-small {
  font-size: 75%;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------
@import "components/slider";

//≥576px
@include media-breakpoint-up(sm) {}

//≥768px
@include media-breakpoint-up(md) {
  #footer {
    padding-bottom: 0px;

    .container {
      .footer-small-screen {
        display: block;
        bottom: 5%;
      }
    }
  }

  #logo {
    background-position-y: 20px;
  }

  #mountains {
    height: 60%;
  }

  #header-text {
    bottom: 4%;
    font-size: 150%;
  }

  #mountains2 {
    height: 590px;
  }

  // #footer {
  //   .container {
  //     top: 12vh;
  //   }
  // }
  .start-game {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 65px;
    }
  }
}

//≥992px
@include media-breakpoint-up(lg) {
  #mountains {
    background-attachment: fixed;
  }

  #logo {
    background-size: 350px;
    background-position-y: 35%;
    background-attachment: fixed;
  }

  #header-text {
    bottom: 5%;
    font-size: 220%;
  }

  #mountains3 {
    min-height: 50dvw;
  }

  #mountains4 {
    min-height: 50dvw;
  }

  #mountains5 {
    min-height: 50dvw;
  }

  #mountains2 {
    min-height: 50dvw;
  }

  #footer {
    .container {
      a {
        background-position: left;
      }
    }
  }
}

//≥1200px
@include media-breakpoint-up(xl) {
  .first-content {
    margin-top: -200px;
  }

  #logo {
    background-size: 350px;
  }

  #mountains {
    height: 70%;
  }

  #mountains2 {
    height: 590px;
  }

  .roadmap {
    &__main-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }

    &__lateral-steps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 175px;
      gap: 60px;

      &__left {
        margin-top: 391px
      }
    }

    &__step-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      align-items: center;
      height: fit-content;
    }

    &__description {
      text-wrap: nowrap;
      font-size: 20px !important;
      line-height: 25px !important;
    }

    &__picture {
      height: 321px;
      width: 321px;
      border-radius: 30px;
      border: solid white 5px;

      &__container {
        position: relative;
      }

      &__check-mark {
        position: absolute;
        height: 84px;
        width: 84px;
        bottom: -10px;
        right: -15px;
        fill: greenyellow;
      }
    }

    &__feather {
      min-height: 4150px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

//≥1400px
@include media-breakpoint-up(xxl) {
  #logo {
    background-size: 410px;
  }

  #header-text {
    bottom: 5%;
    font-size: 220%;
    font-weight: 300;
  }

  // #mountains3 {
  //   height: 668px;
  // }
  // #mountains4 {
  //   height: 745px;
  // }
  // #mountains5 {
  //   min-height: 40dvw;
  // }
  // #mountains2 {
  //   height: 800px;
  // }
  .background-beige {
    margin-bottom: -500px;
    padding-bottom: 500px;
  }
}

@media (orientation: landscape) {
  #clouds {
    height: 100vh;
  }

  #logo {
    background-position-y: 10%;
  }
}

.main-timeline-2 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-2 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-2::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #ffffff;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-2 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-2 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-2::after {
  left: -14px;
}

.card-body {
  background-color: #26262a;
  color: white;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

  /* Place the timelime to the left */
  .main-timeline-2::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-2 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-2::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-2::after,
  .right-2::after {
    left: 18px;
  }

  .left-2::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-2 {
    left: 0%;
  }
}